import { AccountService } from '@/services/api/account';
import { removeToken } from '@/helpers/token';

export const logoutMixin = {
  methods: {
    async logoff() {
      try {
        const accountService = new AccountService();
        const { status } = await accountService.logout();

        if (status === 204) {
          removeToken();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
