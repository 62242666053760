<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    :max-width="400"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-card>
      <v-card-title>
        <h3 class="font-weight-regular">Alterar senha</h3>
        <v-spacer />
        <v-btn icon @click="closeModal" id="btn-close" name="btn-close">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form
          @submit.prevent="execute"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <BasePassword
            outlined
            required
            id="currentPassword"
            name="currentPassword"
            label="Senha atual"
            :rules="[required]"
            v-model="model.currentPassword"
          />

          <BasePassword
            outlined
            required
            id="password"
            name="password"
            label="Nova senha"
            :rules="[required]"
            v-model="model.password"
          />

          <BasePassword
            outlined
            required
            id="confirmPassword"
            name="confirmPassword"
            label="Confirmação da nova senha"
            :rules="[required, verifyConfirmPassword]"
            v-model="model.confirmPassword"
          />

          <BaseButton
            block
            id="btn-execute"
            name="btn-execute"
            type="submit"
            color="primary"
            title="Alterar"
            :disabled="!valid"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { AccountService } from '@/services/api/account';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    model: {
      currentPassword: null,
      password: null,
      confirmPassword: null
    }
  }),

  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  methods: {
    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const accountService = new AccountService();
        const { status } = await accountService.changePassword(this.model);

        if (status === 204) {
          showMessage('success', 'Senha alterada com sucesso!');
          this.closeModal();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    verifyConfirmPassword(v) {
      return v !== this.model.password ? 'Senhas precisam ser idênticas' : true;
    },

    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
