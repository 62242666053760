<template>
  <div>
    <v-menu
      offset-y
      left
      origin="center center"
      transition="scroll-y-transition"
      content-class="b-icon-menu"
      :nudge-bottom="10"
      :min-width="200"
      :max-width="300"
      v-if="profile"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          :icon="!checkMobile"
          :class="['b-icon-account', checkMobile && 'pr-1 pl-2']"
          :color="iconColor"
          rounded
          depressed
          v-on="on"
          v-bind="attrs"
        >
          <span v-if="checkMobile">{{ profile.name.split(' ')[0] }}</span>

          <v-avatar :size="checkMobile ? 26 : 32" :color="iconAvatar">
            {{ createNameIcon(profile.name) }}
          </v-avatar>
        </v-btn>
      </template>

      <v-list dense class="b-icon-menu__list">
        <v-list-item id="list-profile">
          <v-list-item-content class="text-center">
            <v-list-item-title id="profile_name">
              {{ profile.name }}
            </v-list-item-title>
            <v-list-item-subtitle id="profile_email">
              {{ profile.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item ripple @click="dialog = true" id="changePassword">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary" :size="22">fa-solid fa-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item ripple @click="logoff" id="list-logoff">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"> power_settings_new </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <DialogChangePassword
      v-if="dialog"
      :dialog="dialog"
      @close="closeDialogChangePassword"
    />
  </div>
</template>

<script>
import { createNameIcon } from '@/helpers/utils';
import { logoutMixin } from '@/mixins/logout';
import DialogChangePassword from '@/components/account/dialog/dialog-change-password';

export default {
  mixins: [logoutMixin],

  components: {
    DialogChangePassword
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    profile() {
      return this.$store.getters['authentication/getProfile'];
    },

    checkMobile() {
      return this.$vuetify.breakpoint.smAndDown ? false : true;
    },

    iconColor() {
      if (!this.checkMobile) return '';
      const isDark = this.$vuetify.theme.isDark;
      return isDark ? 'primary darken-3' : 'primary darken-1';
    },

    iconAvatar() {
      const isDark = this.$vuetify.theme.isDark;
      return isDark ? 'primary darken-4' : 'primary darken-3';
    }
  },

  methods: {
    createNameIcon,

    closeDialogChangePassword() {
      this.dialog = !this.dialog;
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .b-icon-menu {
    border-top: 2px solid #fff;
    overflow: visible;
    contain: initial;

    &:after {
      border: solid transparent;
      border-bottom-color: #fff;
      border-width: 10px;
      bottom: 100%;
      content: '';
      height: 0;
      pointer-events: none;
      position: absolute;
      right: 5px;
      width: 0;
    }

    &__list {
      padding: 0px;

      .v-list-item {
        &:first-child {
          &:hover {
            background: transparent;
          }
        }

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .b-icon-account {
    font-size: 12px;

    .v-btn__content {
      gap: 0.5rem;
    }
  }
}
</style>
